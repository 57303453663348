import { graphql, useStaticQuery } from "gatsby";

export const useCategories = () => {
  const data = useStaticQuery(graphql`
    query Categories {
      allShopifyCollection {
        edges {
          node {
            id
            title
            handle
            description
            descriptionHtml
            seo {
              title
              description
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    height: 500
                  )
                }
              }
            }
            shopifyId
            products {
              storefrontId
              handle
              seo {
                title
                description
              }
              priceRangeV2 {
                maxVariantPrice {
                  amount
                }
                minVariantPrice {
                  amount
                }
              }
              featuredMedia {
                preview {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          height: 500
                        )
                      }
                    }
                  }
                }
              }
              mediaCount
              media {
                id
                preview {
                  image {
                    localFile {
                      id
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          height: 500
                        )
                      }
                    }
                  }
                }
              }
              title
              vendor
              totalInventory
              tags
              updatedAt
              createdAt
              variants {
                id
                shopifyId
                price
                compareAtPrice
                availableForSale
                inventoryQuantity
              }
            }
          }
        }
      }

      allContentfulBaySmokesCollection {
        edges {
          node {
            id
            slug
            title
            body {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  const categories = data.allShopifyCollection.edges.flatMap(
    ({ node: category }) => {
      const products = category?.products?.map((product) => {
        const inStock = product.variants.find(
          (variant) => variant.availableForSale && variant.inventoryQuantity > 0
        )
          ? true
          : false;

        return { ...product, inStock };
      });

      const categoryContent = data.allContentfulBaySmokesCollection.edges
        .flatMap(({ node: content }) => content)
        .find((content) =>
          content.slug === category?.handle ? content : null
        );

      return { ...category, products, content: categoryContent };
    }
  );

  return categories;
};
