import { useEffect, useState } from "react";
import axios from "axios";

import logo from "../img/bay-smokes/routeLogoBW.png";

const fetchRouteProduct = async () => {
  try {
    const { data } = await axios.get(
      `https://bay-smokes.myshopify.com/products/routeins.json`
    );

    return data?.product;
  } catch (error) {
    console.error("Error fetching Route product:", error);
  }
};

const getRoute = async (cart, subtotal) => {
  const cartRef = cart.id;
  const merchantId = "merch_75aNW4b3woAjQlpwCrpq";
  const currency = "USD";

  const cartItems = cart.lines.edges
    .filter(({ node }) =>
      node.merchandise.product.title.toLowerCase().includes("route")
    )
    .map(({ node }) => ({
      id: node.merchandise.id,
      unit_price: {
        currency: "USD",
        amount: node.merchandise.price.amount,
      },
      quantity: node.quantity,
    }));

  try {
    const { data: quote } = await axios.post(
      "https://api.route.com/v2/quotes",
      {
        merchant_id: merchantId,
        cart: {
          cart_ref: cartRef,
          covered: {
            currency: currency,
            amount: subtotal,
          },
          cart_items: cartItems,
        },
      },
      { headers: { Secret: "3db79323-c4e5-4b87-b7fb-e60d596a1472" } }
    );

    const product = await fetchRouteProduct();
    const matchedVariant = matchVariant(product, quote.premium.amount);

    if (matchedVariant) {
      return {
        product,
        productID: `gid://shopify/Product/${product.id}`,
        variantID: `gid://shopify/ProductVariant/${matchedVariant.id}`,
        price: matchedVariant.price,
      };
    } else {
      throw new Error("No matching variant found");
    }
  } catch (error) {
    console.error("Error fetching quote:", error);
  }
};

const matchVariant = (product, premiumAmount) => {
  const sortedVariants = product.variants.sort(
    (a, b) => parseFloat(a.price) - parseFloat(b.price)
  );

  if (premiumAmount > 0)
    return (
      sortedVariants.find(
        (variant) => parseFloat(variant.price) >= premiumAmount
      ) || sortedVariants[sortedVariants.length - 1]
    );
};

const calcSubtotal = (cart) =>
  cart?.lines?.edges
    ?.reduce(
      (acc, { node: item }) =>
        item.merchandise.product.title.toLowerCase().includes("route")
          ? acc
          : acc + Number(item.merchandise.price.amount) * item.quantity,
      0
    )
    ?.toFixed(2);

export const Route = ({ cart, actions }) => {
  const [quote, setQuote] = useState();
  const [currentVariant, setCurrentVariant] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initRoute = async () => {
      if (!cart?.lines?.edges?.length > 0) return;

      const subtotal = calcSubtotal(cart);
      const res = await getRoute(cart, subtotal);

      if (res?.variantID) {
        actions.addProtection({
          productID: res?.productID,
          variantID: res?.variantID,
        });
        setCurrentVariant(res.variantID);
        setQuote(res);
      }
    };

    initRoute();
  }, []);

  useEffect(() => {
    const updateProtection = async () => {
      if (
        !currentVariant ||
        !cart ||
        !cart.id ||
        !cart?.lines?.edges?.length > 0
      )
        return;

      const subtotal = calcSubtotal(cart);
      const res = await getRoute(cart, subtotal);

      if (res && currentVariant !== res?.variantID) {
        try {
          await actions.removeProtection({ variantID: currentVariant });
          await actions.addProtection({
            productID: res.productID,
            variantID: res.variantID,
          });
          setCurrentVariant(res.variantID);
          setQuote(res);
        } catch (error) {
          console.error("Error updating protection:", error);
        }
      }
    };

    updateProtection();
  }, [cart, currentVariant]);

  const handleAddRoute = () => {
    if (cart?.id) {
      setLoading(true);
      actions.addProtection({
        productID: quote.productID,
        variantID: quote.variantID,
      });
      setCurrentVariant(quote.variantID);
      setLoading(false);
    }
  };

  const handleRemoveRoute = () => {
    setLoading(true);
    actions.removeProtection({ variantID: currentVariant });
    setCurrentVariant(null);
    setLoading(false);
  };

  return (
    <>
      <div
        className="columns is-mobile is-vcentered p-3"
        style={{ border: "1px grey solid", borderRadius: 5 }}>
        <div className="column">
          <div className="is-flex is-flex-direction-row">
            <div className="control">
              <input
                style={{
                  cursor: loading ? "not-allowed" : "pointer",
                  height: "15px",
                  width: "15px",
                }}
                className="mr-2"
                type="checkbox"
                checked={currentVariant || false}
                onChange={currentVariant ? handleRemoveRoute : handleAddRoute}
              />
            </div>
            <p className="is-size-6 has-text-weight-bold mr-1">
              Shipping Protection{" "}
              <img src={logo} alt="Route logo" style={{ height: "10px" }} />
            </p>
          </div>
          <p className="is-size-7">
            By deselecting package protection, Bay Smokes is not liable for
            lost, stolen or damaged orders.
          </p>
        </div>

        <div className="column is-1 p-5">
          <div className="level-item">
            <label className="label">
              {quote?.price && (
                <p className="has-text-weight-bold">${quote?.price}</p>
              )}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
