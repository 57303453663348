import { useContext, useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import useOnClickOutside from "use-onclickoutside";
import useGlobal from "store";
import { Button } from "components/bulma";

import freeProducts from "config/freeProducts";
import { CartContext } from "context/CartContext";
import { Route } from "services/route";

import { getCheckoutToken } from "utils/getCheckoutToken";

import "./cart.css";

const Cart = ({ isCartOpen, setCartOpen }) => {
  const ref = useRef(null);
  const [globalState] = useGlobal();

  const { data, getCart } = useContext(CartContext);

  const [addToCart] = useMutation(ADD_TO_CART);
  const [updateCart] = useMutation(UPDATE_CART);
  const [removeItem] = useMutation(REMOVE_ITEM);

  const closeCart = () => {
    if (isCartOpen) setCartOpen(false);
  };

  useEffect(() => {
    if (globalState.cart.length > 0) setCartOpen(true);
  }, [globalState.cart]);

  const removeCartItem = async ({ item }) => {
    let route;
    const line = data?.cart?.lines?.edges.find(
      ({ node }) => node.merchandise.id === item.merchandise.id
    )?.node;

    if (data?.cart?.lines?.edges.length === 2)
      route = data?.cart?.lines?.edges.find(({ node }) =>
        node.merchandise.product.title.toLowerCase().includes("route")
      )?.node;

    const lineIds = [line.id, route?.id].filter((id) => id);

    await removeItem({
      variables: {
        cartId: globalState.cartID,
        lineIds,
      },
    });

    await getCart();
  };

  const addProtection = async ({ productID, variantID }) => {
    if (!data?.cart?.id) return;

    const exists = data?.cart?.lines?.edges.find(
      ({ node }) => node.merchandise.product.id === productID
    );

    if (!exists) {
      try {
        await addToCart({
          variables: {
            cartId: data.cart.id,
            lines: [
              {
                merchandiseId: variantID,
                quantity: 1,
              },
            ],
          },
        });

        await getCart();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeProtection = async ({ variantID }) => {
    const line = data?.cart?.lines?.edges.find(
      ({ node }) => node.merchandise.id === variantID
    )?.node;

    if (!line) return;

    try {
      await removeItem({
        variables: {
          cartId: globalState.cartID,
          lineIds: [line.id],
        },
      });
    } catch (error) {
      console.log(error);
    }

    await getCart();
  };

  const updateQty = async ({ item, inc }) => {
    const line = data?.cart?.lines?.edges.find(
      ({ node }) => node.merchandise.id === item.merchandise.id
    )?.node;

    const lineItem = {
      merchandiseId: item.merchandise.id,
      quantity: inc
        ? freeProducts.includes(item.merchandise.product.title)
          ? 1
          : line.quantity + 1
        : line.quantity - 1,
      sellingPlanId: line.sellingPlanId,
    };

    await updateCart({
      variables: {
        cartId: globalState.cartID,
        lines: [
          {
            ...lineItem,
            id: line.id,
          },
        ],
      },
    });

    await getCart();
  };

  const sendRefersionCheckoutEvent = (cartID) => {
    const rfsn = {
      cart: cartID,
      id: localStorage.getItem("rfsn_v4_id"),
      url: window.location.href,
      aid: localStorage.getItem("rfsn_v4_aid"),
      cs: localStorage.getItem("rfsn_v4_cs"),
    };

    window?.r?.sendCheckoutEvent(
      rfsn.cart,
      rfsn.id,
      rfsn.url,
      rfsn.aid,
      rfsn.cs
    );
  };

  const checkout = () => {
    if (!data?.cart?.checkoutUrl) return;
    const checkoutToken = getCheckoutToken(data?.cart);
    sendRefersionCheckoutEvent(checkoutToken);
    window.pushowl.trigger("setCheckoutId", checkoutToken);
    window.location.href = data?.cart.checkoutUrl;
  };

  useOnClickOutside(ref, closeCart);

  return (
    <>
      <div
        ref={ref}
        className={isCartOpen ? "side-drawer open" : "side-drawer"}>
        <div>
          <div className="m-5">
            <button
              className="delete is-large is-pulled-right"
              onClick={() => setCartOpen(false)}
            />

            <h2 className="title is-3">Your Cart</h2>
          </div>

          <div
            className="is-flex is-flex-direction-column"
            style={{
              position: "relative",
              overflowY: "auto",
              overflowX: "clip",
              height: "80vh",
              overscrollBehavior: "contain",
            }}>
            <div className="mb-3 ml-2 mr-2">
              {data?.cart?.lines?.edges
                ?.filter(
                  ({ node }) =>
                    !node.merchandise.product.title
                      .toLowerCase()
                      .includes("route")
                )
                .map(({ node: item }) => (
                  <div key={item?.merchandise?.id} className="mt-5">
                    <div className="columns is-mobile is-vcentered">
                      <div className="column is-narrow">
                        <div
                          className="image is-96x96"
                          style={{
                            border: "1px solid #CCC",
                            borderRadius: "5px",
                          }}>
                          <button
                            className="delete is-medium"
                            onClick={() => removeCartItem({ item })}
                            style={{ position: "absolute", top: -10, left: 0 }}
                          />
                          <img
                            src={item?.merchandise?.image?.url}
                            alt={item?.merchandise?.product?.title}
                          />
                        </div>
                      </div>
                      <div className="column is-9">
                        <div className="columns is-mobile">
                          <div className="column">
                            <p className="is-size-6 has-text-black has-text-weight-semibold">
                              {item?.merchandise?.product?.title}
                            </p>
                          </div>
                          <div className="column is-narrow">
                            <p className="is-size-6 has-text-right has-text-weight-bold">
                              $
                              {Number(item.merchandise.price.amount).toFixed(2)}
                            </p>
                          </div>
                          <div className="column is-narrow" />
                        </div>
                        <div className="columns is-mobile is-vcentered">
                          <div className="column">
                            {item.merchandise.selectedOptions.map((option) => (
                              <div
                                className="tag is-small is-rounded mt-1 ml-1"
                                key={option.name}>
                                {option.value}
                              </div>
                            ))}
                          </div>

                          <div className="column is-narrow">
                            <div
                              className={`buttons has-addons are-small mt-2`}>
                              <button
                                className={`button`}
                                onClick={() => updateQty({ item, inc: false })}
                                style={{
                                  borderTopLeftRadius: "5px",
                                  borderBottomLeftRadius: "5px",
                                  borderColor: "#EEE",
                                }}>
                                <span className="has-text-black">-</span>
                              </button>
                              <button
                                className="button is-static"
                                style={{
                                  borderColor: "#EEE",
                                  borderLeftColor: "#FFF",
                                  borderRightColor: "#FFF",
                                }}>
                                <span className="has-text-black">
                                  {item.quantity}
                                </span>
                              </button>
                              <button
                                className={`button`}
                                onClick={() => updateQty({ item, inc: true })}
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                  borderColor: "#EEE",
                                }}>
                                <span className="has-text-black">+</span>
                              </button>
                            </div>
                          </div>
                          <div className="column is-narrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div
              className="mb-6"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                position: "sticky",
                flexGrow: 1,
              }}>
              {data?.cart?.id && data?.cart?.lines?.edges?.length > 0 ? (
                <div className="m-5">
                  <Route
                    cart={data?.cart}
                    actions={{ addProtection, removeProtection }}
                  />
                </div>
              ) : null}
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>

        <nav className="navbar is-fixed-bottom">
          <>
            {data?.cart?.lines?.edges?.length > 0 ? (
              <div className="m-5">
                <Button
                  disabled={!data?.cart.checkoutUrl}
                  color="primary"
                  block
                  size="medium"
                  icon="check-circle"
                  action={() => checkout()}>
                  Checkout $
                  {Number(
                    data?.cart?.estimatedCost?.subtotalAmount.amount
                  ).toFixed(2)}
                </Button>
              </div>
            ) : (
              <div className="m-5">
                <p className="title is-3">Your cart is empty</p>
                <p className="subtitle">Fill in your cart with amazing items</p>
                <Button action={() => setCartOpen(false)}>Shop now</Button>
              </div>
            )}
          </>
        </nav>
      </div>
    </>
  );
};

export default Cart;

const REMOVE_ITEM = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const UPDATE_CART = gql`
  mutation UpdateCart($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                }
              }
              quantity
            }
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

const ADD_TO_CART = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;
